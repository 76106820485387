const http = require('./http');
const axios = require('axios');
const login = (payload) => {
	return new Promise((resolve, reject) => {
		let userdata = {
			geoLocation: payload.geoLocation,
			username: payload.username,
			password: payload.password,
			clientIpAddress: payload.clientIpAddress
		};
		http.post('/doLogin', userdata).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});


	// var userdata = {
	// 	username: payload.username,
	// 	password: payload.password,
	// };
	// return http.post('doLogin', userdata).then((data) => {
	// 	return cb(null, data);
	// }).catch((err) => {
	// 	return cb(err, null);
	// });
};

const createAccount = (payload) => {
	// console.log("payload==========>", payload)
	localStorage.setItem('nameOfUser', payload.email)
	localStorage.setItem('passwordOfUser', payload.password)
	return new Promise((resolve, reject) => {
		let accountData = {
			kazooData: {
				data: {
					apps: {},
					call_forward: {
						substitute: true,
						enabled: false,
						require_keypress: false,
						keep_caller_id: false,
						direct_calls_only: false
					},
					call_restriction: {
						closed_groups: {
							action: 'inherit'
						},
						tollfree_us: {
							action: 'inherit'
						},
						toll_us: {
							action: 'inherit'
						},
						emergency: {
							action: 'inherit'
						},
						caribbean: {
							action: 'inherit'
						},
						did_us: {
							action: 'inherit'
						},
						international: {
							action: 'inherit'
						},
						unknown: {
							action: 'inherit'
						}
					},
					hotdesk: {
						enabled: false,
						id: "",
						require_pin: false,
						keep_logged_in_elsewhere: false
					},
					contact_list: {
						exclude: false
					},
					music_on_hold: {},
					priv_level: "admin",
					vm_to_email_enabled: true,
					fax_to_email_enabled: false,
					verified: true,
					timezone: "Africa/Abidjan",
					record_call: false,
					email: payload.email,
					password: payload.password,
					first_name: payload.firstName,
					last_name: payload.lastName,
					companyName: payload.companyName,
					vatNumber: payload.vatNumber,
					taxNumber: payload.taxNumber,
					phone_number: payload.phoneNumber,
					countryCode: payload.countryCode,
					pwd_mngt_pwd2: payload.confirmPassword,
					username: payload.email,
					pwd_mngt_pwd1: payload.password
				},
				postalAddress: {
					address1: payload.address1,
					address2: payload.address2,
					city: payload.city,
					postalCode: payload.pinCode,
					countryGeoId: payload.countryGeoId,
					stateProvinceGeoId: payload.stateProvinceGeoId
				}
			}
		};

		http.post('createAccount', accountData).then((data) => {
			resolve(data);
		}).catch((error) => {
			reject(error);
		});
	});
};

const forgotPassword = (payload) => {
	return new Promise((resolve, reject) => {
		let userdata = {
			emailAddress: payload.emailAddress
		};
		http.post('/sendForgotPasswordEmailNotification', userdata).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

const getSecurityList = () => {
	return new Promise((resolve, reject) => {
		http.get('/getSecurityQuestions').then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
		});
	});
};

const saveQuestions = (payload) => {
	return new Promise((resolve, reject) => {

		http.post(`/saveQuestions`, payload).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

const emailVerification = (payload) => {
	return new Promise((resolve, reject) => {
		http.post('/createEmailAddressVerification', payload).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

const verifyPasswordRecoveryLink = (data) => {
	return new Promise((resolve, reject) => {
		http.get(`/verifyPasswordRecoveryLink?emailAddress=${data.email}&verifyHash=${data.verifyHash}`).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

const verifyNewLoginRecoveryLink = (data) => {
	return new Promise((resolve, reject) => {
		http.get(`/verifyNewLoginRecoveryLink?emailAddress=${data.email}&verifyHash=${data.verifyHash}`).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

const checkwithpartyId = (payload) => {
	// console.log("partyID", payload)
	const baseUrl = process.env.VUE_APP_LIVE_URL;
	const loginTtoken = localStorage.getItem("authForSecurityQues");
	const ipAddress = localStorage.getItem("ipAddress");
	const countryCode = localStorage.getItem("countryCode");
	// const token = Buffer.from(`${username`, 'utf8').toString('base64')
	const url = baseUrl + `/getUserSecurityQuestions?partyId=${payload.partyId}`
	return new Promise((resolve, reject) => {
		axios.get(url, {
			headers: {
				'Authorization': `Basic ${loginTtoken}`,
				'X-Forwarded-For': `${ipAddress},${countryCode}`
			}
		}).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

// const checkwithpartyId = (payload) => {
// 	return new Promise((resolve, reject) => {
// 		http.get(`/getUserSecurityQuestions?partyId=${payload.partyId}`).then((data) => {
// 			resolve(data);
// 		}).catch((err) => {
// 			reject(err);
// 		});
// 	});
// };

const updatePassword = (payload) => {
	return new Promise((resolve, reject) => {
		http.post('/updateUserPassword', payload).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

const getPartySecurityQuestions = (payload) => {
	return new Promise((resolve, reject) => {
		http.get(`/getPartySecurityQuestions?userLoginId=${payload.userLoginId}`).then((data) => {
			resolve(data);
		}).catch((error) => {
			reject(error);
		});
	});
};


const verifySecurityAnswers = (payload) => {
	return new Promise((resolve, reject) => {
		http.post('/verifySecurityAnswers', payload).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};


const verifyUser = (payload) => {
	return new Promise((resolve, reject) => {
		http.post(`/verifyUser`, payload).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

const updateLimits = (payload) => {
	return new Promise((resolve, reject) => {
		http.post(`/updateLimits`, payload).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

const updateNewEmailAndUserLogin = (payload) => {
	return new Promise((resolve, reject) => {
		http.post(`/updateNewEmailAndUserLogin`, payload).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

const verifySecurityGoogleAuthenticator = (payload) => {
	return new Promise((resolve, reject) => {
		http.post('/verifySecurityGoogleAuthenticator', payload).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};

const verifySecurityEmailAuthenticator = (payload) => {
	return new Promise((resolve, reject) => {
		http.post('/verifySecurityEmailAuthenticator', payload).then((data) => {
			resolve(data);
		}).catch((err) => {
			reject(err);
		});
	});
};


module.exports = {
	login,
	createAccount,
	forgotPassword,
	getSecurityList,
	saveQuestions,
	verifyUser,
	emailVerification,
	verifyPasswordRecoveryLink,
	updatePassword,
	getPartySecurityQuestions,
	verifySecurityAnswers,
	checkwithpartyId,
	updateLimits,
	verifyNewLoginRecoveryLink,
	updateNewEmailAndUserLogin,
	verifySecurityGoogleAuthenticator,
	verifySecurityEmailAuthenticator
};
